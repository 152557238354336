
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Provider } from "mobx-react"
import ChatStore from "../stores/chat.store"
import PostCard from "../components/PostCard"



class BlogPage extends React.Component {

  constructor(props) {
    super(props)
    this.stores = {
      chat: new ChatStore()
    }
  }

  render() {
    const posts = this.props.data.blogs.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <PostCard key={edge.node.id} post={edge.node} />)
    return (
      <Provider
        chat_store={this.stores.chat}
      >
        <Layout scroll={false}>
          <SEO title="Blog Posts" />

          {/* <Chat /> */}

          <div className="content top-offset">
            <h1 className="text-center">Blog</h1>
            <div className="d-flex flex-wrap justify-content-center">
              {posts}
            </div>
          </div>
        </Layout>
      </Provider>
    )
  }
}

export default BlogPage

export const blogPageQuery = graphql`
  query BlogsQuery {
    blogs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail {
              childImageSharp {
                  fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid
                }
              }
          }
          }
        }
      }
    }
  }
`
