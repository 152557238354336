
import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import styles from "./style.module.css"
import Img from "gatsby-image"

const PostCard = ({ post }) => (
    <Link to={`/blog${post.fields.slug}`} aria-label={post.frontmatter.title} className={classnames(styles.wrapper, "bg-light paper-low text-center rounded")}>
        <Img alt={post.frontmatter.title} className="rounded-top" fluid={{...post.frontmatter.thumbnail.childImageSharp.fluid, aspectRatio: 16/9}} />
        <h2>{post.frontmatter.title}</h2>
        <p>
            <small>
                {post.frontmatter.date}
            </small>
        </p>
        <blockquote>
            {post.excerpt}
        </blockquote>
        <br />
    </Link>
)

export default PostCard